'use client'
import React, { useState, useEffect } from 'react'
import { signIn, signOut, useSession, getProviders } from 'next-auth/react'
import Link from 'next/link'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import LocaleSwitcher from './locale-switcher'
import MobileLocaleSwitcher from './mobile-locale-switcher'
import MobileCurrencySwitcher from './mobile-currency-switcher'
import MobileLanguageSetter from './mobile-language-setter'
import { FaHome, FaSearch, FaHeart, FaUser } from "react-icons/fa"
import CurrencyTab from './CurrencyTab'
import tailwindConfig from '@tailwind.config'
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation'
import { IoMenu, IoClose } from "react-icons/io5";
import { Dialog } from '@headlessui/react'
import { company } from '@constants/company'
import { FaLine } from "react-icons/fa6";

const Nav = ({ dictionary, lang, isViewPage = false }) => {
    const currentRoute = usePathname();
    const router = useRouter()

    const { data: session } = useSession()

    const [language, setLanguage] = useState(lang)
    const [providers, setProviders] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('/')
    const [isMap, setIsMap] = useState(false)


    useEffect(() => {
        const setUpProviders = async () => {
            const response = await getProviders()

            setProviders(response)
        }

        setUpProviders()
    }, [])

    useEffect(() => {
        const setupUrl = () => {
            const url = currentRoute
            // console.log(url)
            setUrl(url)
        }

        setupUrl()
        router.refresh()
    }, [currentRoute])

  return (
    <nav className="w-full  bg-maincolor flex items-center justify-between flex-wrap p-0">
    <div className='w-full sm:flex hidden 2xl:w-8/12 items-center justify-between mx-auto p-5'>
        <div className="flex items-center flex-shrink-0 text-white mr-6">

            <LinkComponent href="/" className='relative h-8 w-20'>
                <Image
                    className='object-cover'
                    src={'/logo.png'}
                    // width={imageWidth}
                    // height={imageHeight}
                    alt="Logo"
                    fill={true}
                />


            </LinkComponent>

        </div>
        <div className="flex w-full items-center lg:w-auto">
            <CurrencyTab title="currency" lang={lang} />
            <LocaleSwitcher language={language}/>
            <LinkComponent href="/">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.home}
                </span>
            </LinkComponent>
            <LinkComponent href="/properties">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.forSale}
                </span>
            </LinkComponent>
            <LinkComponent href="/sell">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.listWithUs}
                </span>
            </LinkComponent>
            <LinkComponent href="/new-listings">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.news}
                </span>
            </LinkComponent>
            <LinkComponent href="/contact-us">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.contactUs}
                </span>
            </LinkComponent>
        </div>
    </div>
    {/* Mobile Nav */}
    <>
        <div className='sm:hidden w-full flex items-center justify-between relative p-1 pl-2 z-[120]'>
            <LinkComponent href={'/'}>
                <div className='relative h-6 w-14'>
                    <Image
                        className='object-cover'
                        src={'/logo.png'}
                        alt="Logo"
                        fill={true}
                    />
                </div>
            </LinkComponent>
            <MobileLanguageSetter language={lang} />
            <button onClick={() => setIsOpen(true)}>                    
                <IoMenu 
                    className='border-[1px] border-light rounded-md'
                    size={32}
                    color={tailwindConfig.theme.extend.colors.light}
                /></button>
            <Dialog 
                className='z-[120]' 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
            >
                <div className="fixed  inset-0 bg-black/30 z-[120]" aria-hidden="true" />
                <Dialog.Panel className='bg-white w-[280px] z-[121] absolute top-0 right-0 rounded-l-md rounded-b-md'>
                    <div className='w-full flex justify-end p-1'>
                        <button onClick={() => setIsOpen(false)}>                    
                            <IoClose
                                className='border-[1px] border-light bg-maincolor rounded-md'
                                size={32}
                                color={tailwindConfig.theme.extend.colors.light}
                            />
                        </button>
                    </div>
                    <div className='p-2 w-full'>
                        <Dialog.Title className='text-maincolor pb-2 mb-2 border-b-[1px] border-forth'>
                            <LinkComponent href={`/`} className='hover:color-forth'>
                                {dictionary.title}
                            </LinkComponent>
                        </Dialog.Title>
                        <div className='flex flex-col mb-2'>
                            <div className='sm:hidden flex'>
                                <MobileLocaleSwitcher language={lang} dictionary={dictionary} />
                            </div>
                        </div>
                        <div className='flex flex-col mb-2'>
                            <p className='text-sm text-secondary'>{dictionary.currency}</p>
                            <div className='sm:hidden flex'>
                                <MobileCurrencySwitcher lang={lang} />
                            </div>
                            
                        </div>
                        <div className='w-full bg-pink border-b-[1px] border-forth mb-2'></div>
                        <ul className='w-full flex text-maincolor flex-col mb-4 md:mb-0 md:pl-0'>
                            <LinkComponent href={`/about`} className='w-full hover:color-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.about}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/contact-us`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.contactUs}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/new-listings`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.newestListings}
                                </li>
                            </LinkComponent>
                        </ul>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>
        {!isMap && !isViewPage && (
            <div className='sm:hidden z-50 w-full bg-maincolor flex justify-between bottom-0 fixed border-t px-5 pt-2 pb-2 border-t-secondary'>
                <div>
                    <LinkComponent
                        href="/"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/' ? (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/properties"
                        className='flex flex-col items-center justify-center'
                    >
                        {url !== '/' && url !== '/profile' && url !== '/likes' ? (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}

                                />
                                <span className='text-light text-sm'>
                                    {dictionary.search}
                                </span></>
                        ) : (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}

                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.search}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/likes"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/likes' ? (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        )}
                    </LinkComponent>
                </div>
                <div>
                    <Link
                        href={company.lineUrl}
                        className='flex flex-col items-center justify-center'
                    >
                        <>
                            <FaLine
                                size={25}
                                color={'#25D366'}
                            />
                            <span className='text-forth text-sm'>
                                LINE
                            </span>
                        </>
                    </Link>
                </div>
            </div>
        )}
    </>
</nav>
  )
}

export default Nav